import React, { useEffect, useState } from 'react'
import { Button1, Button3 } from '../../Snippets/Button';
import SearchBar from '../../Snippets/TableCustomHooks/SearchBar';
import Loader from '../../Snippets/Loader';
import Table from '../../Snippets/TableCustomHooks/Table';
import Pagination from '../../Snippets/TableCustomHooks/Pagination';
import EmptyDataAlert from '../../Snippets/TableCustomHooks/EmptyDataAlert';
import ArbitrationAction from './ArbitrationAction';
import { fetchArbitrationDetails } from '../../../Services/ArbitrationManagement/ArbitrationData';
import { useDispatch } from 'react-redux';
import { addCommenceReducer } from '../../../Redux-reducer/ArbitrationManage';

const ArbitrationTable = () => {

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [showDetails, setShowDetails] = useState(true);
    const [userData, setUserData] = useState([]);
    const [isPreviewOpen, setPreviewOpen] = useState(false);
    const [pagination, setPagination] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [error, setError] = useState();
    const [fetchLoading, setFetchLoading] = useState(false);
    const [operation, setOperation] = useState('add');
    const [arbitrationType, setArbitrationType] = useState('All Arbitration');

    const handleSearch = (e) => {
        console.log(e.target.value)
    }

    const fetchArbitrationDetail = async () => {
        setError(null);
        setFetchLoading(true);
        const data = await fetchArbitrationDetails(searchQuery);
        if (data) {
            setUserData(data.response);
            setPagination({
                totalPages: data.totalPages,
                page: data.page,
                hasNextPage: data.hasNextPage,
                hasPrevPage: data.hasPrevPage
            });
            dispatch({ type: 'ARBITRATION', payload: data });
        }
        setIsLoading(false);
        setFetchLoading(false);
    };

    useEffect(() => {
        fetchArbitrationDetail();
    }, [])

    const handleOption = (value) => {
        switch (value) {
            case 'all':
                setArbitrationType('All Arbitration');
                break;
            case 'scheduled':
                setArbitrationType('Scheduled Arbitration');
                break;
            case 'expired':
                setArbitrationType('Expired Licence');
            default:
                return 0;
        }
    }

    const handleSubmit = (values) => {

        const arbitrationDetails = [
            {
                arbitrator_name: values.arbitratorName,
                arbitrator_email: values.arbitratorEmail,
                arbitrator_phone_number: values.arbitratorPhone,
            },
            ...values.additionalArbitrators.map(arbitrator => ({
                arbitrator_name: arbitrator.name,
                arbitrator_email: arbitrator.email,
                arbitrator_phone_number: arbitrator.phone,
            }))
        ];

        const claimantDetails = [
            {
                claimant_name: values.claimantName,
                claimant_email: values.claimantEmail,
                claimant_phonenumber: values.claimantPhone,
            },
            ...values.additionalClaimants.map(claimant => ({
                claimant_name: claimant.name,
                claimant_email: claimant.email,
                claimant_phonenumber: claimant.phone,
            }))
        ];

        const respondentDetails = [
            {
                respondent_name: values.respondentName,
                respondent_email: values.respondentEmail,
                respondent_phone_number: values.respondentPhone,
            },
            ...values.additionalRespondents.map(respondent => ({
                respondent_name: respondent.name,
                respondent_email: respondent.email,
                respondent_phone_number: respondent.phone,
            }))
        ];

        const data = {
            case_no: "12345323",
            case_name: "Sample Case - 2",
            arbitration_name: "Surya",
            arbitrationDetails,
            claimantDetails,
            respondentDetails,
            invoking_date: "2024-11-01T00:00:00Z",
        };

        console.log("Formatted data for API:", data);
        dispatch(addCommenceReducer({ formData: data }));
        setPreviewOpen(false);
        setFetchLoading(true);
        setTimeout(async () => {
            await fetchArbitrationDetail();
        }, 2000);
        // setSubmitLoading(false);
    };


    const handleUpdate = () => {
        console.log("Arbitration update clicked.");
    }

    const handleAddArbitration = () => {
        setOperation('Add');
        setPreviewOpen(true);
    }

    const handleClose = async () => {
        setPreviewOpen(false);
    }

    const columns = React.useMemo(
        () => [
            {
                Header: "S.No",
                accessor: (row, i) => i + 1
            },
            {
                Header: "Case ID",
                accessor: 'case_no'
            },
            {
                Header: "Case Name",
                accessor: "case_name",
            },
            {
                Header: "Status",
                accessor: "arbitration_status",
            },
            {
                Header: "Registration Date",
                accessor: "invoking_date",
            },
            {
                Header: 'Action',
                accessor: 'edit',
                Cell: ({ row }) => (
                    <div className="my-2">
                        <Button3
                            label="Edit"
                        // onClick={() => handleEdit(row.original._id)}
                        />
                    </div>
                ),
            },
        ],
        []
    );

    const data = React.useMemo(() => userData);

    return (
        <>
            <div className="flex ml-64 bg-gray-200 min-h-screen">
                <div className="rounded-md w-[960px] ml-7">
                    <div className='flex py-7'>
                        <h1 className='text-[24px] font-[600] text-heading-clr'>Arbitration Management</h1>
                    </div>
                    <div className='flex gap-5'>
                        <select onChange={(e) => handleOption(e.target.value)} className='px-2 rounded-md' name="" id="">
                            <option value="all">All Arbitration</option>
                            <option value="scheduled">Scheduled Arbitration</option>
                            <option value="expired">Expired Arbitration</option>
                        </select>
                        <div className='w-full'>
                            <SearchBar
                                placeholder='Search CMS User'
                                // searchValue={searchValue}
                                // setSearchValue={setSearchValue}
                                handleSearch={handleSearch}
                            />
                        </div>
                    </div>
                    <div className='mt-10'>
                        <Button1 onClick={handleAddArbitration} label='Commence Arbitration +' />
                    </div>

                    <div>
                        {isLoading && (
                            <div className="-mt-36 -ml-32">
                                <Loader />
                            </div>
                        )}
                        {showDetails ? (
                            <div className="mt-6 mb-20">
                                <div className='text-[20px] font-[600] text-heading-clr'>{arbitrationType}</div>
                                <div className="pt-3 overflow-x-auto mb-10">
                                    <Table
                                        columns={columns}
                                        data={data}
                                    />
                                </div>
                                <Pagination
                                // hasPrevPage={pagination.hasPrevPage}
                                // hasNextPage={pagination.hasNextPage}
                                // goToPrevPage={goToPrevPage}
                                // goToNextPage={goToNextPage}
                                />
                            </div>
                        ) : (
                            <div className='mt-10'>
                                <EmptyDataAlert title="No Data Found" />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {isPreviewOpen && (
                <ArbitrationAction
                    isPreviewOpen={isPreviewOpen}
                    onClose={() => setPreviewOpen(false)}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                    handleUpdate={handleUpdate}
                    // formData={formData}
                    // roleName={roleName}
                    // isLoadingUpdate={isLoadingUpdate}
                    // userId={userId}
                    operation={operation}
                />
            )}
        </>
    )
}

export default ArbitrationTable