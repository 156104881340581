import React, { useState } from 'react'
import { Button3 } from '../../Snippets/Button';
import SearchBar from '../../Snippets/TableCustomHooks/SearchBar';
import Loader from '../../Snippets/Loader';
import Table from '../../Snippets/TableCustomHooks/Table';
import Pagination from '../../Snippets/TableCustomHooks/Pagination';
import EmptyDataAlert from '../../Snippets/TableCustomHooks/EmptyDataAlert';

const OrdersTable = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [userData, setUserData] = useState([]);
    const [isPreviewOpen, setPreviewOpen] = useState(false);
   
    const handleSearch = (e) => {
        console.log(e.target.value)
    }

    const columns = React.useMemo(
        () => [
            {
                Header: "S.No",

            },
            {
                Header: "Case No.",

            },
            {
                Header: "Title of the Matter",
                accessor: "",
            },
            {
                Header: "Date and Time",
                accessor: "",
            },
            {
                Header: 'View Order',
                accessor: 'edit',
                Cell: ({ row }) => (
                    <div className="my-2">
                        <Button3
                            label="View Orders"
                        />
                    </div>
                ),
            },
        ],
        []
    );

    const data = React.useMemo(() => userData);

    return (
        <>
            <div className="flex ml-64 bg-gray-200 min-h-screen">
                <div className="rounded-md w-[960px] ml-7">
                    <div className='flex py-7'>
                        <h1 className='text-[24px] font-[600] text-heading-clr'>Orders</h1>
                    </div>
                    <div>
                        <SearchBar
                            placeholder='Search Here'
                            // searchValue={searchValue}
                            // setSearchValue={setSearchValue}
                            handleSearch={handleSearch}
                        />
                    </div>

                    <div>
                        {isLoading && (
                            <div className="-mt-36 -ml-32">
                                <Loader />
                            </div>
                        )}
                        {showDetails ? (
                            <div className="mt-6 mb-20">
                                <div className="pt-3 overflow-x-auto mb-10">
                                    <Table
                                        columns={columns}
                                        data={data}
                                    />
                                </div>
                                <Pagination
                                // hasPrevPage={pagination.hasPrevPage}
                                // hasNextPage={pagination.hasNextPage}
                                // goToPrevPage={goToPrevPage}
                                // goToNextPage={goToNextPage}
                                />
                            </div>
                        ) : (
                            <div className='mt-10'>
                                <EmptyDataAlert title="No Data Found" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrdersTable