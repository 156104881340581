import { showFailed } from "../../Customs/SweetAlerts";
import axiosInstance from "../../Middleware/AxiosInstanceInterceptor";

// Add Commence
export const addCommence = async (formData) => {
    try {
        const response = await axiosInstance.post(`/arbitration-managment/addCommence`, formData.formData);
        const data = response.data;

        if (response.status === 200) {
            return data;
        }
        else {
            showFailed('Failed.', `${response.status}`);
        }
    }
    catch (error) {
        showFailed('Failed.', `${error.response.statusText}`);
    }
}

// Update Role
export const updateCommence = async (formData, userId, permissions) => {
    const updatedData = {
        name: formData.name,
        email: formData.email,
        phone_number: formData.phone_number,
        gender: formData.gender,
        role: formData.role.toLowerCase(),
        permissions: permissions
    }
    try {
        const response = await axiosInstance.patch(`/cms-arbitration-managment/${userId}`, updatedData);
        const data = response.data;

        if (response.status === 200) {
            return data;
        } else {
            showFailed('Failed.', `${response.status}`);
        }
    } catch (error) {
        showFailed('Failed.', `${error.response.data.error[0]}`);
    }
};

// Delete Role
export const deleteCommence = async (userId) => {
    try {
        const response = await axiosInstance.delete(`/cms-roles/${userId}`);
        const data = response.data;

        if (response.status === 200) {
            return data;
        } else {
            showFailed('Failed.', `${response.status}`);
        }
    } catch (error) {
        showFailed('Failed.', `${error.response.data.error[0]}`);
    }
};