import React, { useState } from "react";
import EmptyDataAlert from "../../Snippets/TableCustomHooks/EmptyDataAlert";
import Loader from "../../Snippets/Loader";
import Table from "../../Snippets/TableCustomHooks/Table";
import { Button1, Button3, Button4 } from "../../Snippets/Button";
import SearchBar from "../../Snippets/TableCustomHooks/SearchBar";
import Pagination from "../../Snippets/TableCustomHooks/Pagination";
import Popup from "reactjs-popup";
import IndividualAction from "./Individual/IndividualAction";
import OrganizationAction from "./Organization/OrganizationAction";

const CustomerTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(true);
  const [userData, setUserData] = useState([]);
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [operation, setOperation] = useState("add");
  const [showContinue, setShowContinue] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [selected, setSelected] = useState("");
  const [openForm, setOpenForm] = useState("");
  const [isActionOpen, setIsActionOpen] = useState(false);

  const formData = {
    email: "",
    phone_number: "",
    name: "",
    gender: "",
    role: "",
  };

  const handleSearch = (e) => {
    console.log(e.target.value);
  };

  const handleAddCustomer = () => {
    setOpenForm(selected);
    setIsActionOpen(true);
  };

  const handleSelectOption = (role) => {
    setSelected("");
    setOpenForm("");
    setSelected(role);
    setShowContinue(true);
  };

  const handleAddUser = () => {
    setOpenForm("");
    setOperation("Add");
    setPreviewOpen(true);
    setShowPopup(true);
  };

  const handleClose = async () => {
    setOpenForm("");
    setPreviewOpen(false);
    setSelected("");
    setShowContinue(false);
  };

  const handleActionClose = () => {
    setIsActionOpen(false);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Person Name",
      },
      {
        Header: "Email ID",
      },
      {
        Header: "Phone Number",
        accessor: "",
      },
      {
        Header: "CUID",
        accessor: "",
      },
      {
        Header: "Type",
        accessor: "",
      },
      {
        Header: "Action",
        accessor: "edit",
        Cell: ({ row }) => (
          <div className="flex my-2">
            <Button3
              label="Edit"
              // onClick={() => handleEdit(row.original._id)}
            />{" "}
            |{" "}
            <Button4
              label="Delete"
              // onClick={() => handleDelete(row.original._id)}
            />
          </div>
        ),
      },
    ],
    []
  );

  const data = React.useMemo(() => userData);

  return (
    <>
      <div className="flex ml-64 bg-gray-200 min-h-screen">
        <div className="rounded-md w-[960px] ml-7">
          <div className="flex py-7">
            <h1 className="text-[24px] font-[600] text-heading-clr">
              Customer Management
            </h1>
          </div>
          <div>
            <SearchBar
              placeholder="Search Here"
              // searchValue={searchValue}
              // setSearchValue={setSearchValue}
              handleSearch={handleSearch}
            />
          </div>
          <div className="mt-10">
            <Button1 onClick={handleAddUser} label="Add Customer +" />
          </div>

          <div>
            {isLoading && (
              <div className="-mt-36 -ml-32">
                <Loader />
              </div>
            )}
            {showDetails ? (
              <div className="mt-6 mb-20">
                <div className="pt-3 overflow-x-auto mb-10">
                  <Table columns={columns} data={data} />
                </div>
                <Pagination
                // hasPrevPage={pagination.hasPrevPage}
                // hasNextPage={pagination.hasNextPage}
                // goToPrevPage={goToPrevPage}
                // goToNextPage={goToNextPage}
                />
              </div>
            ) : (
              <div className="mt-10">
                <EmptyDataAlert title="No Data Found" />
              </div>
            )}
          </div>
        </div>
      </div>

      <Popup
        open={isPreviewOpen}
        modal
        closeOnDocumentClick={false}
        className="custom-popup"
      >
        <div className="popup-content font-avenir fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 backdrop-blur-sm">
          <div className="bg-txtclr w-[575px] h-[272px] mx-auto overflow-y-auto rounded-[14px]">
            <div className="flex justify-between font-avenir text-bgclr px-10 pt-6">
              <h1 className="text-[20px] font-[600] ">Choose Customer Type</h1>
              <button
                type="submit"
                onClick={() => {
                  handleClose();
                }}
                className="text-xl font-bold"
              >
                X
              </button>
            </div>
            <form>
              <div className="flex gap-6 w-[526px] mx-auto mt-7 mb-5">
                <button
                  disabled={selected === "user"}
                  onClick={() => handleSelectOption("user")}
                  className={`w-[250px] h-[95px] text-left shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-[8px] pl-5 ${
                    selected === "user" ? "border-2 border-bgclr" : ""
                  }`}
                >
                  <h2 className="text-[18px] font-[500] pr-5">Individual</h2>
                </button>
                <button
                  disabled={selected === "entity"}
                  onClick={() => handleSelectOption("entity")}
                  className={`w-[250px] h-[95px] text-left shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-[8px] pl-5 ${
                    selected === "entity" ? "border-2 border-bgclr" : ""
                  }`}
                >
                  <h2 className="text-[18px] font-[500] pr-5">Organization</h2>
                </button>
              </div>
            </form>
            <div className="flex justify-end mr-[25px]">
              <button
                disabled={!showContinue}
                onClick={() => handleAddCustomer()}
                className={`px-8 text-txtclr py-3 rounded-md ${
                  showContinue
                    ? "border-2 bg-bgclr border-bgclr"
                    : "bg-gray-400"
                }`}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </Popup>

      {openForm === "user" && (
        <IndividualAction
          isPreviewOpen={isActionOpen}
          handleClose={handleActionClose}
        />
      )}

      {openForm === "entity" && (
        <OrganizationAction
          isPreviewOpen={isActionOpen}
          handleClose={handleActionClose}
        />
      )}
    </>
  );
};

export default CustomerTable;
