import { showFailed } from "../../Customs/SweetAlerts";
import axiosInstance from "../../Middleware/AxiosInstanceInterceptor";

// fetch Roles
export const fetchRole = async (searchQuery) => {
    try {
        const response = await axiosInstance.get(`/roles?search=${searchQuery}`);
        const data = response.data;

        if (response.status === 200) {
            return data;
        } else {
            showFailed('Failed.', `${data?.error}`);
        }
    }
    catch (error) {
        showFailed('Failed.', `${error.response.statusText}`);
    }
}

// Get CMS User by ID
export const getRoleById = async (userId) => {
    try {
        const response = await axiosInstance.get(`/roles/${userId}`);
        const data = response.data;

        if (response.status === 200) {
            return data;
        } else {
            showFailed('Failed.', `${response.status}`);
            throw new Error("Failed to fetch data. Status: " + response.status);
        }
    } catch (error) {
        showFailed('Failed.', `${error.response.data.error[0]}`);
        throw new Error('Error retrieving user data:', error);
    }
};