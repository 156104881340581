import React from 'react'

const EmptyDataAlert = ({ title }) => {
    return (
        <>
            <div className="text-center">
                <div className="flex justify-center">
                    <img src="https://s3.ap-south-1.amazonaws.com/private.clevdoc.com/assets/bank/empty_data.png" alt="0" />
                </div>
                <h2 className="text-[24px] font-semibold -mt-2 text-[#5c5c5c]">{title}</h2>
            </div>
        </>
    )
}

export default EmptyDataAlert