import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { FaSpinner } from 'react-icons/fa';
import * as Yup from "yup";
import Popup from 'reactjs-popup';
import Loader from '../../Snippets/Loader';
import { getRoleById } from '../../../Services/RoleManagement/RoleData';

const RolesAction = ({ isPreviewOpen, name, userId, operation, handleClose, handleSubmit, handleUpdate, isLoadingUpdate }) => {

    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [updatedRole, setUpdatedRole] = useState();

    useEffect(() => {
        if (operation === 'update') {
            // get User by ID
            const getRole = async () => {
                setIsLoading(true);
                try {
                    const data = await getRoleById(userId);
                    console.log("Role by Id : ", data);
                    setUpdatedRole(data.name);
                    setIsLoading(false);
                } catch (error) {
                    setIsLoading(false);
                    throw new Error('Error retrieving user data:', error);
                }
            };
            getRole();
            setIsEditing(true);
        }
    }, []);

    const validationSchema = Yup.object({
        name: Yup.string().required('Role Name is Required').min(3, 'Must be at least 3 characters'),
    });

    return (
        <>
            <Popup open={isPreviewOpen} modal closeOnDocumentClick={false}>
                {(close) => (
                    <div
                        className="popup-style1"
                        style={{ WebkitOverflowScrolling: "touch", scrollbarWidth: "none" }}
                    >
                        {isLoading ? (
                            Loader
                        ) : (
                            <div className="flex container justify-center items-center p-8 rounded-md mx-auto min-h-screen max-h-screen ">
                                <div className="w-[500px] mx-auto">
                                    <Formik
                                        initialValues={{
                                            name: isEditing ? updatedRole : name,
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={isEditing ? (values) => handleUpdate(values) : (values) => handleSubmit(values)}
                                    >

                                        {(formikProps) => (
                                            <Form>
                                                <div className="bg-bg-form px-16 py-10 rounded-lg mt-20 text-tbl-txt-clr">
                                                    <div className="flex justify-end">
                                                        <button
                                                            type="submit"
                                                            onClick={() => {
                                                                handleClose();
                                                                close();
                                                                setUpdatedRole(null);
                                                            }}
                                                            className="text-2xl font-bold"
                                                        >
                                                            X
                                                        </button>
                                                    </div>
                                                    <h2 className="text-xl font-semibold pb-6">
                                                        {isEditing
                                                            ? "Update Role"
                                                            : "Add Role"}
                                                    </h2>
                                                    <div className="mb-2">
                                                        <label htmlFor="name" className="label-style" >
                                                            Role<span className="required">*</span>
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            id="name"
                                                            name="name"
                                                            placeholder="Enter Role "
                                                            className="input-style"
                                                        />
                                                        <ErrorMessage name="name" component="div" className="error" />
                                                    </div>

                                                    <div className="flex justify-center ">
                                                        <button
                                                            type="submit"
                                                            className="btn-style2"
                                                        >
                                                            {isLoadingUpdate ? (
                                                                <FaSpinner className="animate-spin text-3xl" />
                                                            ) : (
                                                                isEditing ? 'Update' : 'Add Now'
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </Popup>
        </>
    )
}

export default RolesAction