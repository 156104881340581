import React, { useEffect, useState } from 'react'
import SearchBar from '../../Snippets/TableCustomHooks/SearchBar'
import { Button1, Button3, Button4 } from '../../Snippets/Button'
import Table from '../../Snippets/TableCustomHooks/Table'
import Pagination from '../../Snippets/TableCustomHooks/Pagination'
import Loader from '../../Snippets/Loader'
import EmptyDataAlert from '../../Snippets/TableCustomHooks/EmptyDataAlert'
import CMSUserAction from './CMSUserAction'
import { fetchCMSRole } from '../../../Services/CMSUserRoles/UserRolesData'
import { fetchRole } from '../../../Services/RoleManagement/RoleData'
import Swal from 'sweetalert2'
import { useDispatch } from 'react-redux'
import { addCMSUser, deleteCMSUser, updateCMSUser } from '../../../Redux-reducer/UserRoleManage'

const CMSUserTable = () => {

    const dispatch = useDispatch();
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showDetails, setShowDetails] = useState(true);
    const [userData, setUserData] = useState([]);
    const [isPreviewOpen, setPreviewOpen] = useState(false);
    const [operation, setOperation] = useState('add');
    const [searchQuery, setSearchQuery] = useState('');
    const [pagination, setPagination] = useState(null);
    const [userId, setUserId] = useState();
    const [error, setError] = useState();
    const [roles, setRoles] = useState([]);
    const [fetchLoading, setFetchLoading] = useState(false);
    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)
    const [isSubmitLoading, setSubmitLoading] = useState(false);

    const formData = {
        email: '',
        phone_number: '',
        name: '',
        gender: '',
        role: ''
    };

    const fetchCMSData = async () => {
        setError(null);
        setFetchLoading(true);
        const data = await fetchCMSRole(searchQuery);
        if (data) {
            setUsers(data.docs);
            setPagination({
                totalPages: data.totalPages,
                page: data.page,
                hasNextPage: data.hasNextPage,
                hasPrevPage: data.hasPrevPage
            });
            dispatch({ type: 'CMSUSER', payload: data });
        }
        setIsLoading(false);
        setFetchLoading(false);
    };

    const fetchData = async () => {
        const data = await fetchRole('');
        setRoles(data.docs);
    };

    useEffect(() => {
        fetchData();
        fetchCMSData();
    }, [])


    const handleSearch = (e) => {
        console.log(e.target.value)
    }

    const handleAddUser = () => {
        console.log("Add User Click");
        setOperation('Add');
        setPreviewOpen(true);
    }

    const handleEdit = (_id) => {
        setOperation('update');
        setUserId(_id)
        setPreviewOpen(true);
    }

    const handleClose = async () => {
        setPreviewOpen(false);
    }

    const handleSubmit = (values, permissions) => {
        dispatch(addCMSUser({ formData: values, permissions: permissions }));
        setPreviewOpen(false);
        setFetchLoading(true);
        setTimeout(async () => {
            await fetchCMSData();
        }, 2000);
        setSubmitLoading(false);
    };

    const handleUpdate = (values, permissions) => {
        console.log("Values: ",values, "Permission: ", permissions);
        setIsLoadingUpdate(true);
        dispatch(updateCMSUser({ formData: values, userId: userId, permissions: permissions }));
        handleClose();
        setFetchLoading(true);
        setTimeout(() => {
            fetchCMSData();
            setIsLoadingUpdate(false);
        }, 500)
    }

    const handleDelete = (_id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1D4ED8",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                setFetchLoading(true);
                dispatch(deleteCMSUser(_id));
                setTimeout(() => {
                    Swal.fire({
                        title: "Deleted!",
                        text: "CMS User has been deleted.",
                        icon: "success"
                    });
                    fetchCMSData();
                }, 2000);
            }
        });
    };

    const columns = React.useMemo(
        () => [
            {
                Header: "Person Name",
                accessor: "name",
            },
            {
                Header: "Email ID",
                accessor: "email",
            },
            {
                Header: "Phone Number",
                accessor: "phone_number",
            },
            {
                Header: "CUID",
                accessor: "system_id",
            },
            {
                Header: "Role",
                accessor: "role",
            },
            {
                Header: 'Action',
                accessor: 'edit',
                Cell: ({ row }) => (
                    <div className="flex my-2">
                        <Button3
                            label="Edit"
                            onClick={() => handleEdit(row.original._id)}
                        /> | <Button4
                            label='Delete'
                            onClick={() => handleDelete(row.original._id)}
                        />
                    </div>
                ),
            },
        ],
        []
    );

    const data = React.useMemo(() => users);

    return (
        <>
            <div className="flex ml-64 bg-gray-200 min-h-screen">
                <div className="rounded-md w-[960px] ml-7">
                    <div className='flex py-7'>
                        <h1 className='text-[24px] font-[600] text-heading-clr'>CMS user Management</h1>
                    </div>
                    <div>
                        <SearchBar
                            placeholder='Search CMS User'
                            // searchValue={searchValue}
                            // setSearchValue={setSearchValue}
                            handleSearch={handleSearch}
                        />
                    </div>
                    <div className='mt-10'>
                        <Button1 onClick={handleAddUser} label='Add User +' />
                    </div>

                    <div>
                        {isLoading && (
                            <div className="-mt-36 -ml-32">
                                <Loader />
                            </div>
                        )}
                        {showDetails ? (
                            <div className="mt-6 mb-20">
                                <div className="pt-3 overflow-x-auto mb-10">
                                    <Table
                                        columns={columns}
                                        data={data}
                                    />
                                </div>
                                <Pagination
                                // hasPrevPage={pagination.hasPrevPage}
                                // hasNextPage={pagination.hasNextPage}
                                // goToPrevPage={goToPrevPage}
                                // goToNextPage={goToNextPage}
                                />
                            </div>
                        ) : (
                            <div className='mt-10'>
                                <EmptyDataAlert title="No Data Found" />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {isPreviewOpen && (
                <CMSUserAction
                    isPreviewOpen={isPreviewOpen}
                    onClose={() => setPreviewOpen(false)}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                    handleUpdate={handleUpdate}
                    formData={formData}
                    isLoadingUpdate={isLoadingUpdate}
                    userId={userId}
                    operation={operation}
                    roles={roles}
                />
            )}
        </>
    )
}

export default CMSUserTable