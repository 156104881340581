import React, { useEffect, useState } from 'react'
import Popup from 'reactjs-popup';
import Loader from '../../Snippets/Loader';
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { FaSpinner } from 'react-icons/fa';
import { getCMSRoleById } from '../../../Services/CMSUserRoles/UserRolesData';

const tabs = ['cms-roles', 'roles', 'bank-roles', 'bank', 'branch', 'loan', 'user', 'organization', 'pod', 'blog', 'payment'];

const CMSUserAction = ({ isPreviewOpen, onClose, userId, roles, operation, handleClose, handleSubmit, handleUpdate, formData, isLoadingUpdate }) => {

    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [updateUserValue, setUpdatedUserValues] = useState([]);
    const [allChecked, setAllChecked] = useState(false);

    useEffect(() => {
        if (operation === 'update') {
            const getUserById = async () => {
                setIsLoading(true);
                try {
                    const data = await getCMSRoleById(userId);
                    let permission;
                    if (data?.permissions.includes("*")) {
                        permission = ["all"];
                        setAllChecked(true);
                    } else {
                        permission = data?.permissions;
                    }
                    setUpdatedUserValues({
                        email: data?.email,
                        name: data?.name,
                        phone_number: data?.phone_number,
                        gender: data?.gender,
                        role: data?.role.toLowerCase(),
                        permission: permission
                    });
                    setIsLoading(false);
                } catch (error) {
                    setIsLoading(false);
                    throw new Error('Error retrieving user data:', error);
                }
            };
            getUserById();
            setIsEditing(true);
        }
    }, [operation]);

    const validationSchema = Yup.object({
        name: Yup.string().required('Name is Required').min(3, 'Must be at least 3 characters'),
        email: Yup.string().required('Email is Required').matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Email is Invalid'),
        phone_number: Yup.string().required('Mobile Number is Required').matches(/^[0-9]{10}$/, 'Mobile Number is Invalid'),
        gender: Yup.string().required('Gender is Required'),
        role: Yup.string().required('Role is Required'),
    });

    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        let updatedPermissions = [...(updateUserValue.permission || [])];

        if (value === 'all') {
            setAllChecked(checked);
            updatedPermissions = checked ? ['*'] : [];
        } else {
            if (checked) {
                updatedPermissions.push(value);
            } else {
                updatedPermissions = updatedPermissions.filter(permission => permission !== value);
            }
            setAllChecked(false);
        }

        setUpdatedUserValues(prevState => ({
            ...prevState,
            permission: updatedPermissions
        }));
    };


    return (
        <Popup open={isPreviewOpen} modal closeOnDocumentClick={false}>
            {(close) => (
                <div
                    className="popup-style1"
                    style={{ WebkitOverflowScrolling: "touch", scrollbarWidth: "none" }}
                >
                    {isLoading ? (
                        Loader
                    ) : (
                        <div className="container p-[0.2px] pt-16 mx-20 mt-5 rounded-md max-h-screen">
                            <div className="w-[1000px] mx-auto">
                                <Formik
                                    initialValues={isEditing ? updateUserValue : formData}
                                    validationSchema={validationSchema}
                                    onSubmit={values => isEditing ? handleUpdate(values, updateUserValue.permission) : handleSubmit(values, updateUserValue.permission)}
                                >
                                    {(formikProps) => (
                                        <Form>
                                            <div className="bg-bg-form px-16 pt-10 pb-16 text-tbl-txt-clr">
                                                <div className="flex justify-end">
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            handleClose();
                                                            close();
                                                            setUpdatedUserValues(null);
                                                        }}
                                                        className="text-2xl font-bold"
                                                    >
                                                        X
                                                    </button>
                                                </div>
                                                <h2 className="text-xl font-semibold pb-6">
                                                    {isEditing
                                                        ? "Update CMS User"
                                                        : "Add CMS User"}
                                                </h2>
                                                <div className='field-split'>
                                                    <div className="mb-6">
                                                        <label
                                                            htmlFor="name"
                                                            className="label-style"
                                                        >
                                                            Authorized Person's Name<span className="required">*</span>
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            placeholder="Enter Name"
                                                            id="name"
                                                            name="name"
                                                            className="input-style"
                                                        />
                                                        <ErrorMessage name="name" component="div" className="error" />
                                                    </div>

                                                    <div className="mb-6">
                                                        <label
                                                            htmlFor="phone_number"
                                                            className="label-style"
                                                        >
                                                            Authorized Phone Number<span className="required">*</span>
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            id="phone_number"
                                                            name="phone_number"
                                                            placeholder="Enter phone number"
                                                            maxLength={10}
                                                            className="input-style"
                                                            onInput={(e) => {
                                                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                            }}
                                                        />
                                                        <ErrorMessage name="phone_number" component="div" className="error" />
                                                    </div>
                                                </div>

                                                <div className='grid md:grid-cols-4 md:gap-5 mt-4'>
                                                    <div className="mb-6 col-span-2">
                                                        <label htmlFor="email" className="label-style" >
                                                            Authorized Email ID<span className="required">*</span>
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            id="email"
                                                            name="email"
                                                            placeholder="Enter email Id"
                                                            className="input-style"
                                                        />
                                                        <ErrorMessage name="email" component="div" className="error" />
                                                    </div>

                                                    <div className='mb-6'>
                                                        <label htmlFor="gender" className="label-style">
                                                            Gender<span className='text-red-800'>*</span>
                                                        </label>
                                                        <Field
                                                            as="select"
                                                            id="gender"
                                                            name="gender"
                                                            className="input-style"
                                                        >
                                                            <option value="">Select</option>
                                                            <option value="male">Male</option>
                                                            <option value="female">Female</option>
                                                            <option value="other">Other</option>
                                                        </Field>
                                                        <ErrorMessage name="gender" component="div" className="error" />
                                                    </div>

                                                    <div className='mb-6'>
                                                        <label htmlFor="role" className="label-style">
                                                            Role<span className='text-red-800'>*</span>
                                                        </label>
                                                        <Field
                                                            as="select"
                                                            id="role"
                                                            name="role"
                                                            className="input-style"
                                                        >
                                                            <option value="">Select Role</option>
                                                            {roles?.map((option) => (
                                                                <option key={option?._id} value={option?.name}>
                                                                    {option?.name}
                                                                </option>
                                                            ))}
                                                        </Field>
                                                        <ErrorMessage name="role" component="div" className="error" />
                                                    </div>
                                                </div>

                                                <div>
                                                    <label htmlFor="role" className="label-style">
                                                        Tab Access<span className='text-red-800'>*</span>
                                                    </label>
                                                    <div className='ml-5 mt-2 grid grid-cols-6 gap-2'>
                                                        <div className="flex items-center mb-2">
                                                            <Field
                                                                id="all"
                                                                name="tab_access"
                                                                value="all"
                                                                type="checkbox"
                                                                className="w-[12px] h-[12px] text-white bg-[#0B1E59] border-[#000000] opacity-[40%] rounded"
                                                                checked={allChecked}
                                                                onChange={handleCheckboxChange}
                                                            />
                                                            <label htmlFor="all" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                                All
                                                            </label>
                                                        </div>
                                                        {tabs.map((permission) => (
                                                            <div className="flex items-center" key={permission}>
                                                                <Field
                                                                    id={permission}
                                                                    name="tab_access"
                                                                    value={permission}
                                                                    type="checkbox"
                                                                    className="w-[12px] h-[12px] text-white bg-[#0B1E59] border-[#000000] opacity-[40%] rounded"
                                                                    checked={(updateUserValue && updateUserValue.permission) ? updateUserValue.permission.includes(permission) : false}
                                                                    onChange={handleCheckboxChange}
                                                                    disabled={allChecked}
                                                                />
                                                                <label htmlFor={permission} className={`${allChecked ? 'text-gray-400' : 'text-gray-900 dark:text-gray-300'} ms-2 text-sm font-medium `}>
                                                                    {permission}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>

                                                </div>

                                                <div className="flex justify-center mt-10">
                                                    <button
                                                        type="submit"
                                                        className="btn-style2"
                                                    >
                                                        {isLoadingUpdate ? (
                                                            <FaSpinner className="animate-spin text-3xl" />
                                                        ) : (
                                                            "Submit"
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    )}
                </div>
            )
            }
        </Popup >
    )
}

export default CMSUserAction