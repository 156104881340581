import { configureStore } from '@reduxjs/toolkit';
import RoleManage from './Redux-reducer/RoleManage';
import UserRoleManage from './Redux-reducer/UserRoleManage';
import ArbitrationManage from './Redux-reducer/ArbitrationManage';

export const store = configureStore({
  reducer: {
    cmsrole: RoleManage,
    cmsusers: UserRoleManage,
    arbitration: ArbitrationManage
  }
})