import { createSlice } from "@reduxjs/toolkit";
import { addRole, deleteRole, updateRole } from "../Services/RoleManagement/RoleAction";

export const initialState = null;

export const reducer = (state, action) => {
  if (action.type === "CMSROLE") {
    return action.payload;
  }
  return state;
};

const cmsRoleSlice = createSlice({
  name: "cmsrole",
  initialState,
  reducers: {
    addRoleReducer: (state, action) => {
      addRole(action.payload);
    },
    updateRoleReducer: (state, action) => {
      const { formData, userId } = action.payload;
      updateRole(formData, userId);
    },
    deleteRoleReducer: (state, action) => {
      deleteRole(action.payload);
    }
  }
});

export const { addRoleReducer, updateRoleReducer, deleteRoleReducer } = cmsRoleSlice.actions;
export default cmsRoleSlice.reducer;