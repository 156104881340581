import React from 'react';
import { useTable } from 'react-table';

const Table = ({ columns, data }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });

    return (
        <table className="min-w-full text-center bg-bgclr9 rounded-3xl" {...getTableProps()}>
            <thead className='h-[52px]'>
                {headerGroups.map(headerGroup => {
                    const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps(); // Destructure key
                    return (
                        <tr key={key} {...restHeaderGroupProps} className='shadow-md'>
                            {headerGroup.headers.map(column => {
                                const { key, ...restColumnProps } = column.getHeaderProps(); // Destructure key
                                return (
                                    <th
                                        key={key} // Pass key directly
                                        {...restColumnProps}
                                        className="text-[#718EBF] text-left font-[600] text-[16px] pl-5 px-2"
                                    >
                                        {column.render('Header')}
                                    </th>
                                );
                            })}
                        </tr>
                    );
                })}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row);
                    const { key, ...restRowProps } = row.getRowProps(); // Destructure key
                    return (
                        <tr key={key} {...restRowProps}>
                            {row.cells.map(cell => {
                                const { key, ...restCellProps } = cell.getCellProps(); // Destructure key
                                return (
                                    <td
                                        key={key} // Pass key directly
                                        {...restCellProps}
                                        className="border-b border-borderclr px-2 py-2"
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default Table;
