import React, { useEffect, useState } from 'react'
import SearchBar from '../../Snippets/TableCustomHooks/SearchBar';
import { Button1, Button3, Button4 } from '../../Snippets/Button';
import Loader from '../../Snippets/Loader';
import Table from '../../Snippets/TableCustomHooks/Table';
import Pagination from '../../Snippets/TableCustomHooks/Pagination';
import EmptyDataAlert from '../../Snippets/TableCustomHooks/EmptyDataAlert';
import { capitalizeFirstLetter } from '../Header/Header';
import RolesAction from './RolesAction';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { fetchRole } from '../../../Services/RoleManagement/RoleData';
import { addRoleReducer, deleteRoleReducer, updateRoleReducer } from '../../../Redux-reducer/RoleManage';
import { showFailed } from '../../../Customs/SweetAlerts';

const RolesTable = () => {

    const dispatch = useDispatch();
    const [searchQuery, setSearchQuery] = useState('');
    const [error, setError] = useState();
    const [isPreviewOpen, setPreviewOpen] = useState(false);
    const [operation, setOperation] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)
    const [userId, setUserId] = useState();
    const [fetchLoading, setFetchLoading] = useState(false);
    const [roles, setRoles] = useState([]);
    const [showDetails, setShowDetails] = useState(false);


    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    }

    const handleAddUser = () => {
        setOperation('Add');
        setPreviewOpen(true);
    }

    const handleClose = async () => {
        setPreviewOpen(false);
    }

    const fetchData = async () => {
        setFetchLoading(true);
        const data = await fetchRole(searchQuery);
        if (data) {
            setRoles(data.docs);
            setShowDetails(true);
            dispatch({ type: 'CMSROLE', payload: data });
        }
        setIsLoading(false);
        setFetchLoading(false);
        setIsLoading(false);
        setFetchLoading(false);
    };

    useEffect(() => {
        fetchData()
    }, []);

    const handleEdit = (_id) => {
        setOperation('update');
        setUserId(_id)
        setPreviewOpen(true);
    }

    const handleUpdate = (values) => {
        setIsLoadingUpdate(true);
        dispatch(updateRoleReducer({ formData: values, userId: userId }));
        handleClose();
        setFetchLoading(true);
        setTimeout(() => {
            fetchData();
            setIsLoadingUpdate(false);
        }, 500)
    }

    const handleDelete = (_id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1D4ED8",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                setFetchLoading(true);
                dispatch(deleteRoleReducer(_id));
                setTimeout(async () => {
                    Swal.fire({
                        title: "Deleted!",
                        text: "Role has been deleted.",
                        icon: "success"
                    });
                    await fetchData();
                }, 1000);
            }
        });
    };

    const handleSubmit = (values) => {
        try {
            dispatch(addRoleReducer(values));
            setPreviewOpen(false);
            setFetchLoading(true);
            setTimeout(async () => {
                await fetchData();
            }, 2000);
        } catch (error) {
            console.error('Error adding user:', error);
            showFailed("Failed to add user. Please try again later.");
        }
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Role',
                accessor: 'name',
                Cell: ({ value }) => capitalizeFirstLetter(value),
            },
            {
                Header: 'Action',
                accessor: 'action',
                Cell: ({ row }) => (
                    <div className="flex my-2">
                        <Button3
                            label="Edit"
                            onClick={() => handleEdit(row.original._id)}
                        /> | <Button4
                            label='Delete'
                            onClick={() => handleDelete(row.original._id)}
                        />
                    </div>
                )
            },
        ],
        []);

    const data = React.useMemo(() => roles);

    return (
        <>

            <div className="flex ml-64 bg-gray-200 min-h-screen">
                <div className="rounded-md w-[960px] ml-7">
                    <div className='flex py-7'>
                        <h1 className='text-[24px] font-[600] text-heading-clr'>Role Management</h1>
                    </div>
                    <div>
                        <SearchBar
                            placeholder='Search role'
                            searchValue={searchQuery}
                            setSearchValue={setSearchQuery}
                            handleSearch={handleSearch}
                        />
                    </div>
                    <div className='mt-10'>
                        <Button1 onClick={handleAddUser} label='Add Role +' />
                    </div>

                    <div>
                        {isLoading && (
                            <div className="-mt-36 -ml-32">
                                <Loader />
                            </div>
                        )}
                        {showDetails ? (
                            <div className="mt-6 mb-20">
                                <div className="pt-3 overflow-x-auto mb-10 w-[380px]">
                                    <Table
                                        columns={columns}
                                        data={data}
                                    />
                                </div>
                                <Pagination
                                // hasPrevPage={pagination.hasPrevPage}
                                // hasNextPage={pagination.hasNextPage}
                                // goToPrevPage={goToPrevPage}
                                // goToNextPage={goToNextPage}
                                />
                            </div>
                        ) : (
                            <div className='my-10'>
                                <EmptyDataAlert title="No Role Found" />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {isPreviewOpen && (
                <RolesAction
                    isPreviewOpen={isPreviewOpen}
                    onClose={() => setPreviewOpen(false)}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                    handleUpdate={handleUpdate}
                    isLoadingUpdate={isLoadingUpdate}
                    userId={userId}
                    operation={operation}
                />
            )}

        </>
    )
}

export default RolesTable