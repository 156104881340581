import React from 'react';

function Button1({ label, onClick }) {
    return (
        <button type="button" onClick={onClick} className="text-white bg-[#0B1E59] hover:bg-heading-clr focus:ring-2 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-[#0B1E59] focus:outline-none dark:focus:ring-headbg-heading-clr">{label}</button>
    );
}

function Button2({ label, onClick }) {
    return (
        <button type="button" onClick={onClick} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-1 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-1 mb-2  focus:outline-none">{label}</button>
    );
}

function Button3({ label, onClick }) {
    return (
        <button type="button" onClick={onClick} className='border bg-bgclr text-white px-2'>{label}</button>
    );
}

function Button4({ label, onClick }) {
    return (
        <button type="button" onClick={onClick} className='border bg-[#d33] text-white px-2'>{label}</button>
    );
}

export { Button1, Button2, Button3, Button4 };