import React, { useState } from 'react'
import * as Yup from "yup";
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { FaSpinner } from 'react-icons/fa';
import Popup from 'reactjs-popup';
import Loader from '../../Snippets/Loader';

const ArbitrationAction = ({ isPreviewOpen, onClose, userId, roles, operation, handleClose, handleSubmit, handleUpdate, formData, isLoadingUpdate }) => {

    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [updateUserValue, setUpdatedUserValues] = useState([]);
    const [initialFormValues, setInitialFormValues] = useState({
        arbitratorName: '',
        arbitratorEmail: '',
        arbitratorPhone: '',
        claimantName: '',
        claimantEmail: '',
        claimantPhone: '',
        respondentName: '',
        respondentEmail: '',
        respondentPhone: '',
        additionalArbitrators: [],
        additionalClaimants: [],
        additionalRespondents: []
    })

    console.log("Commence Arbitration values:- ", initialFormValues);

    const validationSchema = Yup.object({
        arbitratorName: Yup.string().required('Arbitrator Name is Required').min(3, 'Must be at least 3 characters'),
        arbitratorEmail: Yup.string().required('Arbitrator Email is Required').matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Email is Invalid'),
        arbitratorPhone: Yup.string().required('Arbitrator Mobile Number is Required').matches(/^[0-9]{10}$/, 'Mobile Number is Invalid'),
        claimantName: Yup.string().required('Claimant Name is Required').min(3, 'Must be at least 3 characters'),
        claimantEmail: Yup.string().required('Claimant Email is Required').matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Email is Invalid'),
        claimantPhone: Yup.string().required('Claimant Mobile Number is Required').matches(/^[0-9]{10}$/, 'Mobile Number is Invalid'),
        respondentName: Yup.string().required('Respondent Name is Required').min(3, 'Must be at least 3 characters'),
        respondentEmail: Yup.string().required('Respondent Email is Required').matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Email is Invalid'),
        respondentPhone: Yup.string().required('Respondent Mobile Number is Required').matches(/^[0-9]{10}$/, 'Mobile Number is Invalid'),
        additionalArbitrators: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required('Co-Arbitrator Name is Required').min(3, 'Must be at least 3 characters'),
                email: Yup.string().required('Co-Arbitrator Email is Required').matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Email is Invalid'),
                phone: Yup.string().required('Co-Arbitrator Mobile Number is Required').matches(/^[0-9]{10}$/, 'Mobile Number is Invalid'),
            })
        ),
        additionalClaimants: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required('Co-Claimant Name is Required').min(3, 'Must be at least 3 characters'),
                email: Yup.string().required('Co-Claimant Email is Required').matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Email is Invalid'),
                phone: Yup.string().required('Co-Claimant Mobile Number is Required').matches(/^[0-9]{10}$/, 'Mobile Number is Invalid'),
            })
        ),
        additionalRespondents: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required('Co-Respondent Name is Required').min(3, 'Must be at least 3 characters'),
                email: Yup.string().required('Co-Respondent Email is Required').matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Email is Invalid'),
                phone: Yup.string().required('Co-Respondent Mobile Number is Required').matches(/^[0-9]{10}$/, 'Mobile Number is Invalid'),
            })
        ),
    });

    return (
        <>
            <Popup open={isPreviewOpen} modal closeOnDocumentClick={false}>
                {(close) => (
                    <div
                        className="popup-style1"
                        style={{ WebkitOverflowScrolling: "touch", scrollbarWidth: "none" }}
                    >
                        {isLoading ? (
                            Loader
                        ) : (
                            <div className="container p-[0.2px] pt-16 mx-20 mt-5 rounded-md max-h-screen">
                                <div className="w-[1000px] mx-auto">
                                    <Formik
                                        initialValues={initialFormValues}
                                        validationSchema={validationSchema}
                                        onSubmit={isEditing ? (values) => handleUpdate(values) : (values) => handleSubmit(values)}
                                    >
                                        {(formikProps) => (
                                            <Form>
                                                <div className="bg-bg-form px-16 pt-5 pb-16 text-tbl-txt-clr">
                                                    <div className="flex justify-end">
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                handleClose();
                                                                close();
                                                                setUpdatedUserValues(null);
                                                            }}
                                                            className="text-2xl font-bold"
                                                        >
                                                            X
                                                        </button>
                                                    </div>
                                                    <h2 className="text-[18px] font-[600] pb-6">
                                                        {isEditing
                                                            ? "Update CMS User"
                                                            : "Add CMS User"}
                                                    </h2>
                                                    <div className='text-[16px] font-[600] text-heading-clr'>
                                                        Arbitrator Details
                                                    </div>
                                                    <div className='field-split2'>
                                                        <div className="mb-6">
                                                            <label
                                                                htmlFor="arbitratorName"
                                                                className="label-style"
                                                            >
                                                                Arbitrator Name<span className="required">*</span>
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                placeholder="Enter Name"
                                                                id="arbitratorName"
                                                                name="arbitratorName"
                                                                className="input-style"
                                                            />
                                                            <ErrorMessage name="arbitratorName" component="div" className="error" />
                                                        </div>

                                                        <div className="mb-6">
                                                            <label
                                                                htmlFor="arbitratorEmail"
                                                                className="label-style"
                                                            >
                                                                Arbitrator Email<span className="required">*</span>
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                id="arbitratorEmail"
                                                                name="arbitratorEmail"
                                                                placeholder="Enter email number"
                                                                className="input-style"
                                                            />
                                                            <ErrorMessage name="arbitratorEmail" component="div" className="error" />
                                                        </div>

                                                        <div className="mb-6">
                                                            <label
                                                                htmlFor="arbitratorPhone"
                                                                className="label-style"
                                                            >
                                                                Arbitrator Phone Number<span className="required">*</span>
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                id="arbitratorPhone"
                                                                name="arbitratorPhone"
                                                                placeholder="Enter phone number"
                                                                maxLength={10}
                                                                className="input-style"
                                                                onInput={(e) => {
                                                                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                                }}
                                                            />
                                                            <ErrorMessage name="arbitratorPhone" component="div" className="error" />
                                                        </div>
                                                    </div>

                                                    <FieldArray name="additionalArbitrators">
                                                        {({ push, remove }) => (
                                                            <div>
                                                                {formikProps.values.additionalArbitrators?.map((_, index) => (
                                                                    <div key={index}>
                                                                        <div className="field-split2">
                                                                            <div>
                                                                                <label htmlFor={`additionalArbitrators.${index}.name`} className='label-style'>
                                                                                    Additional Arbitrator Name<span className="required">*</span>
                                                                                </label>
                                                                                <Field className="input-style" name={`additionalArbitrators.${index}.name`} type="text" placeholder="Enter Co-Arbitrator Name" />
                                                                                <ErrorMessage className="error" name={`additionalArbitrators.${index}.name`} component="div" />
                                                                            </div>

                                                                            <div>
                                                                                <label htmlFor={`additionalArbitrators.${index}.email`} className='label-style'>
                                                                                    Additional Arbitrator Email<span className="required">*</span>
                                                                                </label>
                                                                                <Field name={`additionalArbitrators.${index}.email`} className="input-style" type="text" placeholder="Enter Co-Arbitrator Email" />
                                                                                <ErrorMessage className="error" name={`additionalArbitrators.${index}.email`} component="div" />
                                                                            </div>

                                                                            <div>
                                                                                <label htmlFor={`additionalArbitrators.${index}.phone`} className='label-style'>
                                                                                    Additional Arbitrator Phone Number<span className="required">*</span>
                                                                                </label>
                                                                                <Field name={`additionalArbitrators.${index}.phone`} className="input-style" type="text" placeholder="Enter Co-Arbitrator Phone Number" maxLength={10} />
                                                                                <ErrorMessage className="error" name={`additionalArbitrators.${index}.phone`} component="div" />
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex justify-start mb-2'>
                                                                            <button className='text-red-600 underline text-xl' type="button" onClick={() => remove(index)}>Remove</button>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                                <div className='flex justify-start gap-3 items-center mb-7 -mt-2'>
                                                                    <div className='text-[14px] font-[500] text-[#666666]'>
                                                                        Add Another Arbitrator
                                                                    </div>
                                                                    <button type="button" className='bg-bgclr rounded-[3px] text-txtclr py-1.5 text-[14px] font-[400] px-7' onClick={() => push({ name: '', email: '', phone: '' })}>Add</button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </FieldArray>


                                                    <div className='text-[16px] font-[600] text-heading-clr'>
                                                        Claimant Details
                                                    </div>
                                                    <div className='field-split2'>
                                                        <div className="mb-6">
                                                            <label
                                                                htmlFor="claimantName"
                                                                className="label-style"
                                                            >
                                                                Claimant Name<span className="required">*</span>
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                placeholder="Enter Name"
                                                                id="claimantName"
                                                                name="claimantName"
                                                                className="input-style"
                                                            />
                                                            <ErrorMessage name="claimantName" component="div" className="error" />
                                                        </div>

                                                        <div className="mb-6">
                                                            <label
                                                                htmlFor="claimantEmail"
                                                                className="label-style"
                                                            >
                                                                Claimant Email<span className="required">*</span>
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                id="claimantEmail"
                                                                name="claimantEmail"
                                                                placeholder="Enter Email number"
                                                                className="input-style"
                                                            />
                                                            <ErrorMessage name="claimantEmail" component="div" className="error" />
                                                        </div>

                                                        <div className="mb-6">
                                                            <label
                                                                htmlFor="claimantPhone"
                                                                className="label-style"
                                                            >
                                                                Claimant Phone Number<span className="required">*</span>
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                id="claimantPhone"
                                                                name="claimantPhone"
                                                                placeholder="Enter phone number"
                                                                maxLength={10}
                                                                className="input-style"
                                                                onInput={(e) => {
                                                                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                                }}
                                                            />
                                                            <ErrorMessage name="claimantPhone" component="div" className="error" />
                                                        </div>
                                                    </div>

                                                    <FieldArray name="additionalClaimants">
                                                        {({ push, remove }) => (
                                                            <div>
                                                                {formikProps.values.additionalClaimants?.map((_, index) => (
                                                                    <div key={index}>
                                                                        <div className="field-split2">
                                                                            <div>
                                                                                <label htmlFor={`additionalClaimants.${index}.name`} className='label-style'>
                                                                                    Additional Claimant Name<span className="required">*</span>
                                                                                </label>
                                                                                <Field className="input-style" name={`additionalClaimants.${index}.name`} type="text" placeholder="Enter Co-Claimant Name" />
                                                                                <ErrorMessage className="error" name={`additionalClaimants.${index}.name`} component="div" />
                                                                            </div>

                                                                            <div>
                                                                                <label htmlFor={`additionalClaimants.${index}.email`} className='label-style'>
                                                                                    Additional Claimant Email<span className="required">*</span>
                                                                                </label>
                                                                                <Field name={`additionalClaimants.${index}.email`} className="input-style" type="text" placeholder="Enter Co-Claimant Email" />
                                                                                <ErrorMessage className="error" name={`additionalClaimants.${index}.email`} component="div" />
                                                                            </div>

                                                                            <div>
                                                                                <label htmlFor={`additionalClaimants.${index}.phone`} className='label-style'>
                                                                                    Additional Claimant Phone Number<span className="required">*</span>
                                                                                </label>
                                                                                <Field name={`additionalClaimants.${index}.phone`} className="input-style" type="text" placeholder="Enter Co-Claimant Phone Number" maxLength={10} />
                                                                                <ErrorMessage className="error" name={`additionalClaimants.${index}.phone`} component="div" />
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex justify-start mb-2'>
                                                                            <button className='text-red-600 underline text-xl' type="button" onClick={() => remove(index)}>Remove</button>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                                <div className='flex justify-start gap-3 items-center mb-7 -mt-2'>
                                                                    <div className='text-[14px] font-[500] text-[#666666]'>
                                                                        Add Another Claimant
                                                                    </div>
                                                                    <button type="button" className='bg-bgclr rounded-[3px] text-txtclr py-1.5 text-[14px] font-[400] px-7' onClick={() => push({ name: '', email: '', phone: '' })}>Add</button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </FieldArray>

                                                    <div className='text-[16px] font-[600] text-heading-clr'>
                                                        Respondent Details
                                                    </div>
                                                    <div className='field-split2'>
                                                        <div className="mb-6">
                                                            <label
                                                                htmlFor="respondentName"
                                                                className="label-style"
                                                            >
                                                                Respondent Name<span className="required">*</span>
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                placeholder="Enter Name"
                                                                id="respondentName"
                                                                name="respondentName"
                                                                className="input-style"
                                                            />
                                                            <ErrorMessage name="respondentName" component="div" className="error" />
                                                        </div>

                                                        <div className="mb-6">
                                                            <label
                                                                htmlFor="respondentEmail"
                                                                className="label-style"
                                                            >
                                                                Respondent Email<span className="required">*</span>
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                id="respondentEmail"
                                                                name="respondentEmail"
                                                                placeholder="Enter phone number"
                                                                className="input-style"
                                                            />
                                                            <ErrorMessage name="respondentEmail" component="div" className="error" />
                                                        </div>

                                                        <div className="mb-6">
                                                            <label
                                                                htmlFor="respondentPhone"
                                                                className="label-style"
                                                            >
                                                                Respondent Phone Number<span className="required">*</span>
                                                            </label>
                                                            <Field
                                                                type="text"
                                                                id="respondentPhone"
                                                                name="respondentPhone"
                                                                placeholder="Enter phone number"
                                                                maxLength={10}
                                                                className="input-style"
                                                                onInput={(e) => {
                                                                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                                                }}
                                                            />
                                                            <ErrorMessage name="respondentPhone" component="div" className="error" />
                                                        </div>
                                                    </div>

                                                    <FieldArray name="additionalRespondents">
                                                        {({ push, remove }) => (
                                                            <div>
                                                                {formikProps.values.additionalRespondents?.map((_, index) => (
                                                                    <div key={index}>
                                                                        <div className="field-split2">
                                                                            <div>
                                                                                <label htmlFor={`additionalRespondents.${index}.name`} className='label-style'>
                                                                                    Additional Respondent Name<span className="required">*</span>
                                                                                </label>
                                                                                <Field className="input-style" name={`additionalRespondents.${index}.name`} type="text" placeholder="Enter Co-Respondent Name" />
                                                                                <ErrorMessage className="error" name={`additionalRespondents.${index}.name`} component="div" />
                                                                            </div>

                                                                            <div>
                                                                                <label htmlFor={`additionalRespondents.${index}.email`} className='label-style'>
                                                                                    Additional Respondent Email<span className="required">*</span>
                                                                                </label>
                                                                                <Field name={`additionalRespondents.${index}.email`} className="input-style" type="text" placeholder="Enter Co-Respondent Email" />
                                                                                <ErrorMessage className="error" name={`additionalRespondents.${index}.email`} component="div" />
                                                                            </div>

                                                                            <div>
                                                                                <label htmlFor={`additionalRespondents.${index}.phone`} className='label-style'>
                                                                                    Additional Respondent Phone Number<span className="required">*</span>
                                                                                </label>
                                                                                <Field name={`additionalRespondents.${index}.phone`} className="input-style" type="text" placeholder="Enter Co-Respondent Phone Number" maxLength={10} />
                                                                                <ErrorMessage className="error" name={`additionalRespondents.${index}.phone`} component="div" />
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex justify-start mb-2'>
                                                                            <button className='text-red-600 underline text-xl' type="button" onClick={() => remove(index)}>Remove</button>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                                <div className='flex justify-start gap-3 items-center mb-7 -mt-2'>
                                                                    <div className='text-[14px] font-[500] text-[#666666]'>
                                                                        Add Another Respondent
                                                                    </div>
                                                                    <button type="button" className='bg-bgclr rounded-[3px] text-txtclr py-1.5 text-[14px] font-[400] px-7' onClick={() => push({ name: '', email: '', phone: '' })}>Add</button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </FieldArray>

                                                    <div className="flex justify-center mt-10">
                                                        <button
                                                            type="submit"
                                                            className="btn-style2"
                                                        >
                                                            {isLoadingUpdate ? (
                                                                <FaSpinner className="animate-spin text-3xl" />
                                                            ) : (
                                                                "Submit"
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        )}
                    </div>
                )
                }
            </Popup >
        </>
    )
}

export default ArbitrationAction