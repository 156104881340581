import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_LEGAL_CMS_PLATFORM_API_BASE_URL,
});
console.log('NEXT_PUBLIC_API_BASE_URL', process.env.REACT_APP_LEGAL_CMS_PLATFORM_API_BASE_URL);
axiosInstance.defaults.headers.post["Content-Type"] = "application/json";

axiosInstance.interceptors.request.use(async (config) => {
  const url = config.url;
  const endPoints = url.split('/')[1].split('?')[0];
  const legalCMSToken = cookies.get('authToken');
  if (legalCMSToken) {
    config.headers.Authorization = `Bearer ${legalCMSToken}`;
    switch (endPoints) {
      case "roles":
        config.headers['module-id'] = 'roles';
        break;
      case "cms-roles":
        config.headers['module-id'] = 'cms-roles';
        break;
      case "arbitration-managment":
        config.headers['module-id'] = 'commence';
        break;
      case "addCommence":
        config.headers['module-id'] = 'commence';
        break;
      default:
        break;
    }
  }
  return config;
});

export default axiosInstance;