import { createSlice } from "@reduxjs/toolkit";
import { addCommence, deleteCommence, updateCommence } from "../Services/ArbitrationManagement/ArbitrationAction";

export const initialState = null;

export const reducer = (state, action) => {
  if (action.type === "ARBITRATION") {
    return action.payload;
  }
  return state;
};

const cmsArbitrationSlice = createSlice({
  name: "arbitration",
  initialState,
  reducers: {
    addCommenceReducer: (state, action) => {
      addCommence(action.payload);
    },
    updateCommenceReducer: (state, action) => {
      const { formData, userId } = action.payload;
      updateCommence(formData, userId);
    },
    deleteCommenceReducer: (state, action) => {
      deleteCommence(action.payload);
    }
  }
});

export const { addCommenceReducer, updateCommenceReducer, deleteCommenceReducer } = cmsArbitrationSlice.actions;
export default cmsArbitrationSlice.reducer;