import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdKeyboardArrowDown } from "react-icons/md";

const Sidebar = () => {
    const location = useLocation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isOnboardingMenuOpen, setIsOnboardingMenuOpen] = useState(false);
    const [isBorrowerMenuOpen, setIsBorrowerMenuOpen] = useState(false);
    const [permission, setpermission] = useState(['*']);
    const navigate = useNavigate();

    // useEffect(() => {
    //     if (token) {
    //         const decodedData = decodeJwtToken(token);
    //         console.log("Decode information - > ", decodedData);
    //         setpermission(decodedData.permissions)
    //     } else {
    //         showFailed('Failed...', 'You are not Authorized.');
    //         navigate('/');
    //     }
    // }, [])

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleOnboardingMenu = () => {
        setIsOnboardingMenuOpen(!isOnboardingMenuOpen);
    };

    const toggleBorrowerMenu = () => {
        setIsBorrowerMenuOpen(!isBorrowerMenuOpen);
    };

    return (
        <>
            <div className="mt-[112px]">
                <button
                    onClick={toggleMenu}
                    aria-controls="default-sidebar"
                    type="button"
                    className="inline-flex items-center pt-20 pr-16 ms-3 text-sm  text-gray-500 rounded-lg sm:hidden   "
                >
                    {isMenuOpen ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    ) : (
                        <GiHamburgerMenu size={30} />
                    )}
                </button>

                <div
                    id="default-sidebar"
                    className={`fixed z-50 h-full w-64 bg-heading-clr dark:bg-gray-800 transition-transform duration-300 ease-in-out transform ${isMenuOpen
                        ? "mt-4 pl-12 h-60 bg-black overflow-y-auto"
                        : "hidden md:block"
                        } sm:relative sm:translate-x-0 md:fixed`}
                    aria-label="Sidebar"
                >
                    <div className="px-3 py-4 pt-5 overflow-y-auto h-screen pb-36 text-white">
                        <ul className="space-y-2">
                            {permission?.includes("*") || permission?.includes("dashboard") ? (
                                <li className={`li-style ${location.pathname === "/dashboard" ? "bankNavbarStyle" : ""}`}>
                                    <Link to="/dashboard">
                                        <span className="flex-1 ms-3 whitespace-nowrap">Dashboard</span>
                                    </Link>
                                </li>
                            ) : null}

                            {permission?.includes("*") || permission?.includes("roles") ? (
                                <li className={`li-style ${location.pathname === "/dashboard/manageRole" ? "bankNavbarStyle" : ""}`}>
                                    <Link to="/dashboard/manageRole">
                                        <span className="flex-1 ms-3 whitespace-nowrap">Role Management</span>
                                    </Link>
                                </li>
                            ) : null}

                            {permission?.includes("*") || permission?.includes("user") ? (
                                <li className={`li-style ${location.pathname === "/dashboard/manageUser" ? "bankNavbarStyle" : ""}`}>
                                    <Link to="/dashboard/manageUser">
                                        <span className="flex-1 ms-3 whitespace-nowrap">CMS User Management</span>
                                    </Link>
                                </li>
                            ) : null}

                            {permission?.includes("*") || permission?.includes("customer") ? (
                                <li className={`li-style ${location.pathname === "/dashboard/manageCustomer" ? "bankNavbarStyle" : ""}`}>
                                    <Link to="/dashboard/manageCustomer">
                                        <span className="flex-1 ms-3 whitespace-nowrap">Customer Management</span>
                                    </Link>
                                </li>
                            ) : null}

                            {permission?.includes("*") || permission?.includes("arbitartion") ? (
                                <li className={`li-style ${location.pathname === "/dashboard/mngArbi" ? "bankNavbarStyle" : ""}`}>
                                    <Link to="/dashboard/mngArbi">
                                        <span className="flex-1 ms-3 whitespace-nowrap">Arbitration Managment</span>
                                    </Link>
                                </li>
                            ) : null}

                            {permission?.includes("*") || permission?.includes("order") ? (
                                <li className={`li-style ${location.pathname === "/dashboard/orders" ? "bankNavbarStyle" : ""}`}>
                                    <Link to="/dashboard/orders">
                                        <span className="flex-1 ms-3 whitespace-nowrap">Orders</span>
                                    </Link>
                                </li>
                            ) : null}

                            {permission?.includes("*") || permission?.includes("payment") ? (
                                <li className={`li-style ${location.pathname === "/dashboard/paymentManage" ? "bankNavbarStyle" : ""}`}>
                                    <Link to="/dashboard/paymentManage">
                                        <span className="flex-1 ms-3 whitespace-nowrap">Payment Revenue</span>
                                    </Link>
                                </li>
                            ) : null}

                            {permission?.includes("*") || permission?.includes("arbi") ? (
                                <li className={`li-style ${location.pathname === "/dashboard/pod" ? "bankNavbarStyle" : ""}`}>
                                    <Link to="/dashboard/pod">
                                        <span className="flex-1 ms-3 whitespace-nowrap">Proof of Delivery</span>
                                    </Link>
                                </li>
                            ) : null}



                            {permission?.includes("*") || permission?.includes("order") ? (
                                <li className={`li-style ${location.pathname === "/dashboard/blogs" ? "bankNavbarStyle" : ""}`}>
                                    <Link to="/dashboard/blogs">
                                        <span className="flex-1 ms-3 whitespace-nowrap">Blog Management</span>
                                    </Link>
                                </li>
                            ) : null}

                            {permission?.includes("*") || permission?.includes("scheduled-email") ? (
                                <li className={`li-style ${location.pathname === "/dashboard/schEmail" ? "bankNavbarStyle" : ""}`}>
                                    <Link to="/dashboard/schEmail">
                                        <span className="flex-1 ms-3 whitespace-nowrap">Email Schedular</span>
                                    </Link>
                                </li>
                            ) : null}

                            {permission?.includes("*") || permission?.includes("whatsapp") ? (
                                <li className={`li-style ${location.pathname === "/dashboard/whatsappCamp" ? "bankNavbarStyle" : ""}`}>
                                    <Link to="/dashboard/whatsappCamp">
                                        <span className="flex-1 ms-3 whitespace-nowrap">WhatsApp Campaign</span>
                                    </Link>
                                </li>
                            ) : null}

                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sidebar;
