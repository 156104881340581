import { Route, Routes } from "react-router-dom";
import Header from "./Components/Layout/Header/Header";
import Login from "./Components/Layout/Auth/Login";
import Sidebar from "./Components/Snippets/Sidebar";
import CMSUserTable from "./Components/Layout/CMSUserManagement/CMSUserTable";
import { Toaster } from "react-hot-toast";
import ArbitrationTable from "./Components/Layout/ArbitrationManagement/ArbitrationTable";
import RolesTable from "./Components/Layout/RoleManagement/RolesTable";
import CustomerTable from "./Components/Layout/CustomerManagement/CustomerTable";
import PaymentTable from "./Components/Layout/PaymentRevenue/PaymentTable";
import OrdersTable from "./Components/Layout/Orders/OrdersTable";
import { createContext, useReducer } from "react";
import { initialState, reducer } from "./Redux-reducer/RoleManage";

export const UserContext = createContext();

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <div className="bg-bgclr4 h-screen">
      <UserContext.Provider value={{ state, dispatch }}>
        <Routes>
          {/* <Route path="/" element={<Login />} /> */}
          <Route
            path="/*"
            element={
              <>
                <Header />
                <Routes>
                  <Route index element={<Login />} />
                  <Route
                    path="/dashboard/*"
                    element={
                      <>
                        <Sidebar />
                        <Routes>
                          <Route path="/manageUser" element={<CMSUserTable />} />
                          <Route path="/mngArbi" element={<ArbitrationTable />} />
                          <Route path="/manageRole" element={<RolesTable />} />
                          <Route path="/orders" element={<OrdersTable />} />
                          <Route path="/manageCustomer" element={<CustomerTable />} />
                          <Route path="/paymentManage" element={<PaymentTable />} />
                        </Routes>
                      </>
                    }
                  />
                </Routes>
              </>
            }
          />
        </Routes>
      </UserContext.Provider>
      <Toaster
        position="top-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: '',
          duration: 5000,
          style: {
            marginTop: '70px'
          },
          success: {
            duration: 3000,
            theme: {
              primary: 'green',
              secondary: 'black',
            },
          },
        }}
      />
    </div>
  );
}

export default App;
