import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import Popup from "reactjs-popup";
import * as Yup from "yup";
import Loader from "../../../Snippets/Loader";
import { FaSpinner } from "react-icons/fa";
import Select from "react-select";
import { City, Country, State } from "country-state-city";

const IndividualAction = ({
  isPreviewOpen,
  onClose,
  userId,
  roles,
  operation,
  handleClose,
  handleSubmit,
  handleUpdate,
  formData,
  isLoadingUpdate,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updateUserValue, setUpdatedUserValues] = useState([]);
  const [allChecked, setAllChecked] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const indiaOption = Country.getAllCountries().find(
    (country) => country.name === "India"
  );

  const handleStateChange = (selectedState, setValues) => {
    setValues((prevValues) => ({
      ...prevValues,
      state_name: selectedState.name,
      city_name: "", // Reset city name when state changes
    }));
    setSelectedState(selectedState);
  };

  const handleCityChange = (selectedCity, setValues) => {
    setValues((prevValues) => ({
      ...prevValues,
      city_name: selectedCity.name,
    }));
    setSelectedCity(selectedCity);
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Name is Required")
      .min(3, "Must be at least 3 characters"),
    email: Yup.string()
      .required("Email is Required")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Email is Invalid"
      ),
    phone_number: Yup.string()
      .required("Mobile Number is Required")
      .matches(/^[0-9]{10}$/, "Mobile Number is Invalid"),
    gender: Yup.string().required("Gender is Required"),
    role: Yup.string().required("Role is Required"),
  });

  return (
    <>
      <Popup open={isPreviewOpen} modal closeOnDocumentClick={false}>
        {(close) => (
          <div
            className="popup-style1"
            style={{ WebkitOverflowScrolling: "touch", scrollbarWidth: "none" }}
          >
            {isLoading ? (
              Loader
            ) : (
              <div className="container p-[0.2px] pt-16 mx-20 mt-5 rounded-md max-h-screen">
                <div className="w-[1000px] mx-auto">
                  <Formik
                    initialValues={isEditing ? updateUserValue : formData}
                    validationSchema={validationSchema}
                    onSubmit={(values) =>
                      isEditing
                        ? handleUpdate(values, updateUserValue.permission)
                        : handleSubmit(values, updateUserValue.permission)
                    }
                  >
                    {(formikProps) => (
                      <Form>
                        <div className="bg-bg-form px-16 pt-10 pb-16 text-tbl-txt-clr">
                          <div className="flex justify-end">
                            <button
                              type="button"
                              onClick={() => {
                                handleClose();
                                close();
                                setUpdatedUserValues(null);
                              }}
                              className="text-2xl font-bold"
                            >
                              X
                            </button>
                          </div>
                          <h2 className="text-xl font-semibold pb-6">
                            {isEditing ? "Update Customer" : "Add Customer"}
                          </h2>
                          <div className="field-split">
                            <div className="mb-4">
                              <label htmlFor="name" className="label-style">
                                Select User Type{" "}
                                <span className="required">*</span>
                              </label>
                              <Field
                                as="select"
                                id="type"
                                name="type"
                                className="input-style"
                              >
                                <option value="">Select Role</option>
                                <option value="">Claimant</option>
                                <option value="">Arbitrator</option>
                              </Field>
                              <ErrorMessage
                                name="name"
                                component="div"
                                className="error"
                              />
                            </div>

                            <div className="mb-4">
                              <label htmlFor="bar_cid" className="label-style">
                                Bar Council ID
                                <span className="required">*</span>
                              </label>
                              <Field
                                type="text"
                                id="bar_cid"
                                name="bar_cid"
                                placeholder="Enter Bar Council ID"
                                className="input-style"
                              />
                              <ErrorMessage
                                name="bar_cid"
                                component="div"
                                className="error"
                              />
                            </div>
                          </div>

                          {/* Name */}
                          <div className="field-split2">
                            <div className="mb-4">
                              <label
                                htmlFor="first_name"
                                className="label-style"
                              >
                                First Name<span className="required">*</span>
                              </label>
                              <Field
                                type="text"
                                placeholder="Enter First Name"
                                id="first_name"
                                name="first_name"
                                className="input-style"
                              />
                              <ErrorMessage
                                name="first_name"
                                component="div"
                                className="error"
                              />
                            </div>

                            <div className="mb-4">
                              <label
                                htmlFor="middle_name"
                                className="label-style"
                              >
                                Middle Name
                              </label>
                              <Field
                                type="text"
                                id="middle_name"
                                name="middle_name"
                                placeholder="Enter middle name"
                                maxLength={10}
                                className="input-style"
                              />
                              <ErrorMessage
                                name="middle_name"
                                component="div"
                                className="error"
                              />
                            </div>

                            <div className="mb-4">
                              <label
                                htmlFor="last_name"
                                className="label-style"
                              >
                                Last Name<span className="required">*</span>
                              </label>
                              <Field
                                type="text"
                                id="last_name"
                                name="last_name"
                                placeholder="Enter last name"
                                className="input-style"
                              />
                              <ErrorMessage
                                name="last_name"
                                component="div"
                                className="error"
                              />
                            </div>
                          </div>

                          {/* PAN, Bar council ID */}
                          <div className="field-split">
                            <div className="mb-4">
                              <label
                                htmlFor="panNumber"
                                className="label-style"
                              >
                                PAN<span className="required">*</span>
                              </label>
                              <Field
                                type="text"
                                placeholder="Enter PAN"
                                id="panNumber"
                                name="panNumber"
                                className="input-style"
                              />
                              <ErrorMessage
                                name="panNumber"
                                component="div"
                                className="error"
                              />
                            </div>
                            <div className="mb-4 w-full">
                              <label
                                htmlFor="gender"
                                className="block text-sm font-medium text-txtclr2"
                              >
                                Gender<span className="text-red-800">*</span>
                              </label>
                              <select
                                id="gender"
                                name="gender"
                                className="input-style"
                                // value={formData.gender} // Make sure to bind the value
                                onChange={(e) =>
                                  formikProps.setFieldValue(
                                    "gender",
                                    e.target.value
                                  )
                                } // Handle change
                              >
                                <option value="">Select</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>
                              </select>
                              <ErrorMessage
                                name="gender"
                                component="div"
                                className="text-red-600 font-semibold"
                              />
                            </div>
                          </div>

                          {/* Email, whatsapp */}
                          <div className="field-split">
                            <div className="mb-4">
                              <label htmlFor="email" className="label-style">
                                Email ID<span className="required">*</span>
                              </label>
                              <Field
                                type="text"
                                id="email"
                                name="email"
                                placeholder="Enter email Id"
                                className="input-style"
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="error"
                              />
                            </div>

                            <div className="mb-4">
                              <label htmlFor="whatsapp" className="label-style">
                                Whatsapp<span className="required">*</span>
                              </label>
                              <Field
                                type="text"
                                id="whatsapp"
                                name="whatsapp"
                                placeholder="Enter whatsapp number"
                                className="input-style"
                              />
                              <ErrorMessage
                                name="whatsapp"
                                component="div"
                                className="error"
                              />
                            </div>
                          </div>

                          {/* DOB, Country */}
                          <div className="field-split">
                            <div className="mb-4">
                              <label htmlFor="dob" className="label-style">
                                DOB<span className="required">*</span>
                              </label>
                              <Field
                                type="date"
                                id="dob"
                                name="dob"
                                placeholder="Enter dob"
                                className="input-style"
                              />
                              <ErrorMessage
                                name="dob"
                                component="div"
                                className="error"
                              />
                            </div>

                            <div className="mt-2" disabled>
                              <label
                                htmlFor="country_name"
                                className="label-style"
                              >
                                Country<span className="text-red-800">*</span>
                              </label>
                              <Select
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: state.isFocused
                                      ? "#EBEBEB"
                                      : "#e2e8f0",
                                    border: "2px solid #e2e8f0",
                                    padding: "4px",
                                    marginTop: "1px",
                                  }),
                                }}
                                name="country_name"
                                id="country_name"
                                options={[{ ...indiaOption, isDisabled: true }]} // Disable India
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.name}
                                value={selectedCountry || indiaOption}
                                onChange={(item) => setSelectedCountry(item)}
                              />
                              <ErrorMessage
                                name="country_name"
                                component="div"
                                className="text-red-600 font-semibold"
                              />
                            </div>
                          </div>

                          {/* State, City, address */}
                          <div className="field-split">
                            <div>
                              <div className="mb-4">
                                <label htmlFor="state" className="label-style">
                                  State<span className="text-red-800">*</span>
                                </label>
                                <Select
                                  name="state_name"
                                  id="state_name"
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      borderColor: state.isFocused
                                        ? "#EBEBEB"
                                        : "#e2e8f0",
                                      border: "2px solid #e2e8f0",
                                      padding: "4px",
                                      marginTop: "1px",
                                    }),
                                  }}
                                  options={State?.getStatesOfCountry("IN")}
                                  getOptionLabel={(options) => {
                                    return options["name"];
                                  }}
                                  getOptionValue={(options) => {
                                    return options["name"];
                                  }}
                                  value={selectedState}
                                  // onChange={(item) => {
                                  //     setSelectedState(item);
                                  // }}
                                  onChange={(selectedState) =>
                                    handleStateChange(
                                      selectedState,
                                      formikProps.setValues
                                    )
                                  }
                                />
                                <ErrorMessage
                                  name="state_name"
                                  component="div"
                                  className="text-red-600 font-semibold"
                                />
                              </div>

                              <div className="mb-4">
                                <label htmlFor="state" className="label-style">
                                  City<span className="text-red-800">*</span>
                                </label>
                                <Select
                                  name="city_name"
                                  id="city_name"
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      borderColor: state.isFocused
                                        ? "#EBEBEB"
                                        : "#e2e8f0",
                                      border: "2px solid #e2e8f0",
                                      padding: "4px",
                                      marginTop: "1px",
                                    }),
                                  }}
                                  options={City.getCitiesOfState(
                                    selectedState?.countryCode,
                                    selectedState?.isoCode
                                  )}
                                  getOptionLabel={(options) => {
                                    return options["name"];
                                  }}
                                  getOptionValue={(options) => {
                                    return options["name"];
                                  }}
                                  value={selectedCity}
                                  // onChange={(item) => {
                                  //     setSelectedCity(item);
                                  // }}
                                  onChange={(selectedCity) =>
                                    handleCityChange(
                                      selectedCity,
                                      formikProps.setValues
                                    )
                                  }
                                />
                                <ErrorMessage
                                  name="city_name"
                                  component="div"
                                  className="text-red-600 font-semibold"
                                />
                              </div>
                            </div>

                            <div className="mb-4">
                              <label htmlFor="address" className="label-style">
                                Correspondence Address
                                <span className="required">*</span>
                              </label>
                              <Field
                                as="textarea"
                                placeholder="Enter First Name"
                                id="address"
                                name="address"
                                className="input-style h-[126px]"
                              />
                              <ErrorMessage
                                name="address"
                                component="div"
                                className="error"
                              />
                            </div>
                          </div>

                          <div className="flex justify-center mt-10">
                            <button type="submit" className="btn-style2">
                              {isLoadingUpdate ? (
                                <FaSpinner className="animate-spin text-3xl" />
                              ) : (
                                "Add Customer"
                              )}
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            )}
          </div>
        )}
      </Popup>
    </>
  );
};

export default IndividualAction;
