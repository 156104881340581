import React, { useState, useEffect, useRef } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';

export function capitalizeFirstLetter(string) {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const Header = () => {
    const navigate = useNavigate();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [user, setUser] = useState({});
    const dropdownRef = useRef(null);

    // useEffect(() => {
    //     if (cmsJwtToken) {
    //         const decodecmsJwtToken = (token) => {
    //             const base64Url = token.split('.')[1];
    //             const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    //             const jsonPayload = decodeURIComponent(
    //                 atob(base64)
    //                     .split('')
    //                     .map(function (c) {
    //                         return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    //                     })
    //                     .join('')
    //             );

    //             return JSON.parse(jsonPayload);
    //         };

    //         const data = decodecmsJwtToken(cmsJwtToken);
    //         setUser(data);
    //     }
    // }, [cmsJwtToken]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleLogout = () => {
        const confirmed = window.confirm('Are you sure you want to logout?');
        // if (confirmed) {
        //     cookies.remove("jwt");
        //     navigate('/login');
        //     window.location.reload();
        // }
    };

    const handleAvatarClick = () => {
        setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown menu on avatar click
    };

    return (
        <div className='fixed z-50 top-0 left-0 w-full bg-blue-950 h-28'>
            <nav className="md:flex justify-between px-10 md:min-h-[10vh] md:h-24 h-32 pb-3 items-center text-txtclr mt-4">
                <div className='flex md:gap-8 justify-between gap-5 mb-5 md:mb-0 pt-5 md:pt-0'>
                    <div className='flex md:items-center '>
                        <Link to='/'>
                            <img className="md:w-64 w-44" src='https://cdn.clevdoc.com/assets/logo/clevdoc_logo.png' alt="no" />
                        </Link>
                    </div>
                </div>
                <div className="flex items-center text-white justify-end gap-1">
                    {/* {cmsJwtToken ? ( */}
                        <div className="relative inline-block" ref={dropdownRef}>
                            <button onClick={handleAvatarClick} className='flex text-xl hover:text-red-600'>
                                Welcome, {capitalizeFirstLetter(user?.first_name) || capitalizeFirstLetter(user?.name)} <div className='mt-1.5'><FaAngleDown /></div>
                            </button>

                            {isDropdownOpen && (
                                <div className="absolute right-5 mt-1 px-3 py-1.5 w-28 text-center bg-white rounded shadow">
                                    <ul className='text-md'>
                                        {/* <li>
                             <button onClick={(e)=>GoToDashboard(e)}>
                                 <div className='text-bgclr border-b pb-2 pt-3 hover:font-semibold'>
                                     Go to Dashboard
                                 </div>
                             </button>
                         </li> */}
                                        <li>
                                            <button
                                                className='flex p-1 py-2 rounded hover:font-semibold text-bgclr'
                                                onClick={(e) => handleLogout(e)}
                                            >
                                                Log out
                                            </button>
                                        </li>

                                    </ul>
                                </div>
                            )}
                        </div>
                    {/* ) : ( */}
                        <Link to='/'>
                            <button className='flex text-md p-1 px-2 rounded border hover:text-red-600 hover:border-red-600 ring-1'>
                                Log In
                            </button>
                        </Link>
                    {/* )} */}
                </div>
            </nav>
            {/* <div className='container mx-auto flex justify-between items-center mt-5'>

            </div> */}
        </div>
    );
}

export default Header;
