import React from "react";

const SearchBar = ({ placeholder, searchValue, setSearchValue, handleSearch }) => {
    return (
        <div className="flex gap-5 mr-5 searchBar">
            <input
                type="text"
                placeholder={placeholder}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                className="searchBar-input"
            />
            <button
                onClick={handleSearch}
                className="searchBar-btn"
            >
                Search
            </button>
        </div>
    );
};

export default SearchBar;
